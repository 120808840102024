import pointIsBelowLine from '@/store/helpers/annotations/pointIsBelowLine';
import { logger } from '@/store/logger';

const annotationIntersectsBoxDiagonal = (rTreeAnnotation, {
  startX, startY, endX, endY,
}) => {
  /*
    Expects start -> end point to be ordered left to right.
    Calculates whether a rTreeAnnotation's bounding box intersects with an axis-aligned line.
    Checks whether all points in the rectangle are 'below' or 'above' the line.
    Algorithm could be sped up to avoid checks based on gradient in the future.
  */
  if (startX > endX) {
    logger.error(`Expected points ordered from left to right. Given: startX=${startX}, endX=${endX}`);
  }
  const topLeftX = rTreeAnnotation.minX;
  const topLeftY = rTreeAnnotation.minY;
  const topLeftIsBelowLine = pointIsBelowLine(startX, startY, endX, endY, topLeftX, topLeftY);

  const topRightX = rTreeAnnotation.maxX;
  const topRightY = rTreeAnnotation.minY;
  const topRightIsBelowLine = pointIsBelowLine(startX, startY, endX, endY, topRightX, topRightY);

  const bottomLeftX = rTreeAnnotation.minX;
  const bottomLeftY = rTreeAnnotation.maxY;
  const bottomLeftIsBelowLine = pointIsBelowLine(startX, startY, endX, endY, bottomLeftX, bottomLeftY);

  const bottomRightX = rTreeAnnotation.maxX;
  const bottomRightY = rTreeAnnotation.maxY;
  const bottomRightIsBelowLine = pointIsBelowLine(startX, startY, endX, endY, bottomRightX, bottomRightY);

  return !(
    (topLeftIsBelowLine && topRightIsBelowLine && bottomLeftIsBelowLine && bottomRightIsBelowLine)
    || (!topLeftIsBelowLine && !topRightIsBelowLine && !bottomLeftIsBelowLine && !bottomRightIsBelowLine)
  );
};

const orderCoordinatesLeftToRight = ({
  startX, startY, endX, endY,
}) => {
  if (startX <= endX) {
    return {
      startX, startY, endX, endY,
    };
  }
  return {
    startX: endX, startY: endY, endX: startX, endY: startY,
  };
};

export default (treeForPage, startX, startY, endX, endY) => {
  logger.debug('annotationsThatIntersectLine', startX, startY, endX, endY, treeForPage);
  const sortedXValues = (new Float64Array([startX, endX])).sort();
  const sortedYValues = (new Float64Array([startY, endY])).sort();
  logger.debug('sortedValues:', sortedXValues, sortedYValues);

  const searchParams = {
    minX: sortedXValues[0], minY: sortedYValues[0], maxX: sortedXValues[1], maxY: sortedYValues[1],
  };
  logger.debug('searchParams:', searchParams);
  const annotationsInBBox = treeForPage.search(searchParams);
  const lineCoords = orderCoordinatesLeftToRight({
    startX, startY, endX, endY,
  });

  return annotationsInBBox.filter((a) => annotationIntersectsBoxDiagonal(a, lineCoords));
};
