import {
  AnnotationLocator,
  AnnotationLocatorsToProcess,
  ExtractedAnnotationsDocument,
  SelectedAnnotationSet,
} from '@/hooks/interfaces/pdfViewerInterfaces';
import isString from '@/store/helpers/isString';
import { logger } from '@/store/logger';

const selectedAnnotationSetIds = (sas: SelectedAnnotationSet[]): Set<string> => {
  const idSet: Set<string> = new Set();
  sas.forEach((a) => {
    const { customData } = a.primary;
    if (customData === null || customData.nid === undefined) {
      logger.error('CustomData not set for annotation', a);
    } else {
      const nid = String(customData.nid);
      idSet.add(nid);
    }
  });

  return idSet;
};

const customDataIsAnnotationLocator = (customData: Object): customData is AnnotationLocator => ('nid' in customData && 'page' in customData);

const selectedAnnotationSetToAnnotationLocator = (
  sas: SelectedAnnotationSet,
): AnnotationLocator => {
  const { customData } = sas.primary;
  if (customData === null || !isString(customData.nid) || !Number.isFinite(customData.page)) {
    throw Error(`CustomData not set for annotation set: ${sas}`);
  }

  if (customDataIsAnnotationLocator(customData)) {
    return customData;
  }

  throw Error(`CustomData not set for annotation set: ${sas}`);
};

/* Annotation Collection (which to delete and create) */
const collectAnnotationsToCreateAndDeleteForReplace = (
  newLocators: AnnotationLocator[], currentlySelected: SelectedAnnotationSet[],
): AnnotationLocatorsToProcess => {
  logger.debug('collectAnnotationsToCreateAndDeleteForReplace', newLocators, currentlySelected);
  const currentLocators = currentlySelected.map(selectedAnnotationSetToAnnotationLocator);
  const currentlySelectedIds = selectedAnnotationSetIds(currentlySelected);
  const newIds = new Set(newLocators.map((l) => l.nid));
  logger.debug('Currently selected ids:', currentlySelectedIds);

  const toCreate: AnnotationLocator[] = [];
  const toDelete: AnnotationLocator[] = [];
  const toPersist: AnnotationLocator[] = [];
  newLocators.forEach((al) => {
    if (currentlySelectedIds.has(al.nid)) {
      toDelete.push(al);
    }
    toCreate.push(al);
  });

  currentLocators.forEach((al) => {
    // Delete annotations that weren't selected just now
    if (!newIds.has(al.nid)) {
      toDelete.push(al);
    }
  });

  logger.debug('Post collectAnnotationsToCreateAndDeleteForReplace (C, P, D):', toCreate, toPersist, toDelete);

  return {
    toPersist,
    toCreate,
    toDelete,
  };
};

/**
 * Create any annotations that are new, but not previously selected
 * Keep (persist) any annotations that are currentlySelected but not new
 */
const collectAnnotationsToCreateAndDeleteForAppend = (
  newLocators: AnnotationLocator[], currentlySelected: SelectedAnnotationSet[],
): AnnotationLocatorsToProcess => {
  logger.debug('collectAnnotationsToCreateAndDeleteForAppend', newLocators, currentlySelected);
  const currentLocators = currentlySelected.map(selectedAnnotationSetToAnnotationLocator);
  const currentlySelectedIds = new Set(currentLocators.map((l) => l.nid));
  const newIds = new Set(newLocators.map((l) => l.nid));
  logger.debug('Newly selected locators:', currentLocators);

  const toCreate: AnnotationLocator[] = [];
  const toPersist: AnnotationLocator[] = [];
  const toDelete: AnnotationLocator[] = [];
  newLocators.forEach((al) => {
    if (currentlySelectedIds.has(al.nid)) {
      toDelete.push(al);
    } else {
      toCreate.push(al);
    }
  });

  // Persist all old selected annotations that haven't been selected just now:
  currentLocators.forEach((l) => {
    // If an old selected annotation isn't in the new list, persist the old annotation
    if (!newIds.has(l.nid)) {
      toPersist.push(l);
    }
  });
  logger.debug('Post collectAnnotationsToCreateAndDeleteForAppend (C, P, D):', toCreate, toPersist, toDelete);

  return {
    toPersist,
    toCreate,
    toDelete,
  };
};

const filterOutParentalAnnotations = (
  alsToProcess: AnnotationLocatorsToProcess, annotationLookup: ExtractedAnnotationsDocument,
): AnnotationLocatorsToProcess => {
  logger.debug('als:', alsToProcess, 'lookup:', annotationLookup);

  // De-select any 'parental' annotations that don't have all of their 'child' annotations selected
  const toPersistAndCreateIds = new Set(
    alsToProcess.toCreate.map((al) => al.nid).concat(alsToProcess.toPersist.map((al) => al.nid)),
  );

  const toCreateFiltered = alsToProcess.toCreate.filter((al) => {
    const extAnnotation = annotationLookup[al.page][al.nid];
    if (!Array.isArray(extAnnotation.c) || extAnnotation.c.length === 0) {
      return true;
    }

    // Array is parental, check if all of its children are also selected:
    return extAnnotation.c.every(
      (childId) => toPersistAndCreateIds.has(childId),
    );
  });

  return {
    toPersist: alsToProcess.toPersist,
    toCreate: toCreateFiltered,
    toDelete: alsToProcess.toDelete,
  };
};

export default () => ({
  collectAnnotationsToCreateAndDeleteForAppend,
  collectAnnotationsToCreateAndDeleteForReplace,
  filterOutParentalAnnotations,
  selectedAnnotationSetToAnnotationLocator,
});
