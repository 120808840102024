import { logger } from '@/store/logger';

export default (lineX0, lineY0, lineX1, lineY1, pointX, pointY) => {
  // Flip the y co-ordinates because the pdf has an origin at the top-left
  const lx0 = lineX0;
  const ly0 = -lineY0;
  const lx1 = lineX1;
  const ly1 = -lineY1;
  const px = pointX;
  const py = -pointY;

  const v0 = [lx1 - lx0, ly1 - ly0];
  const v1 = [lx1 - px, ly1 - py];
  const crossProduct = v0[0] * v1[1] - v0[1] * v1[0];

  if (crossProduct === 0) {
    logger.debug('On the same vector!');
    return true;
  }

  return crossProduct > 0;
};
