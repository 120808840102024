<template>
  <div
    :key="documentRequestId"
    class="pdf-container"
  >
    <div class="pdf-viewer-container-wrapper">
      <div class="pdf-viewer-container" />
    </div>
  </div>
</template>
<script>
import clonedeep from 'lodash.clonedeep';
import PSPDFKit from 'pspdfkit';
import { useToast } from 'vue-toastification';
import { mapActions, mapGetters } from 'vuex';
import isString from '@/store/helpers/isString';
import usePspdfkitWrapperViewer from '@/hooks/usePspdfkitWrapperViewer';
import objHasKey from '@/store/helpers/objHasKey';
import rTreeAnnotationsInsideBoundingBox from '@/store/helpers/annotations/rTreeAnnotationsInsideBoundingBox';
import rTreeAnnotationsThatIntersectLine from '@/store/helpers/annotations/rTreeAnnotationsThatIntersectLine';
import createSelectedInfo from '@/store/helpers/annotations/createSelectedInfo';
import { warningMessages, infoMessages } from '@/store/helpers/display/toastMessages';
import FEATURE_FLAGS from '@/store/helpers/featureFlags';
import Api from '../../store/helpers/api';
import isSet from '../../store/helpers/isSet';

export default {
  /**
   * @typedef {Object} AnnotationInfo
   * @property {int} page - Page number
   * @property {number} nid - Annotation (node) id
   *
   * @typedef {Object} BackendAnnotation
   * @property {number[]} l - Normalised annotation co-ordinates [left, right, width, height]
   * @property {{n: {v: number}}} nv - Normalised value (additional object keys for different cell types)
   * @property {string} t - Text value for the annotation
   * @property {number} tc - ID of the column crosshair
   * @property {number} tr - ID of the row crosshair
   */
  props: {
    documentRequestId: {
      type: String,
      required: true,
    },
    localDocumentPath: {
      type: String,
      default: '',
    },
  },
  setup(props, context) {
    return usePspdfkitWrapperViewer(props, context);
  },
  data: () => ({
    baseUrl: `${window.location.origin}/`,
    cachedAnnotationNodes: {},
    containerSelector: '.pdf-viewer-container',
    filePaths: null,
    instance: null,
    licenseKey: process.env.VUE_APP_PSPDFKIT_LICENSE_KEY,
    selectedAnnotations: [],
    toast: useToast(),
    channelMetricSelected: null,
    annotationBorderWidth: 2, // referenced in custom pspdfkit css file
    thresholdForNotRenderingAllAnnotations: 0,
    shouldJumpToAnnotation: true,
    shouldCopyAnnotation: false,
    annotationsUrl: null, // Pre-signed url for downloading annotations (json) from S3
    pdfUrl: null, // Pre-signed url for downloading document (pdf) from S3
    language: 'native',
    enableUXRefresh: FEATURE_FLAGS.ENABLE_UX_REFRESH,
  }),
  computed: {
    ...mapGetters({
      annotations: 'annotations/all',
      annotationByPageAndId: 'annotations/byPageAndId',
      annotationTrees: 'annotations/trees',
      offline: 'documentRequest/offline',
      readOnlyMode: 'localisation/documentsReadOnlyMode',
    }),
    enableLanguageToggle() {
      return this.filePaths?.annotations_en && FEATURE_FLAGS.MULTI_LINGUAL_SUPPORT;
    },
  },
  watch: {
    annotations(newAnnotations) {
      if (newAnnotations && Object.keys(newAnnotations).length > 0 && isSet(this.instance)) {
        this.drawAllAnnotations();
      }
    },
    instance(newInstance) {
      if (this.annotations && Object.keys(this.annotations).length > 0 && isSet(newInstance)) {
        this.drawAllAnnotations();
      }
    },
  },
  async mounted() {
    if (!isSet(this.documentRequestId) || this.documentRequestId === '') {
      this.$log.error('Error loading pdf with document request id:', this.documentRequestId);
      return;
    }

    // Request S3 filepaths and initialise document
    this.filePaths = await this.fetchFilePaths(this.documentRequestId);
    this.pdfUrl = this.getPDFUrl(this.language ?? 'native');
    this.annotationsUrl = this.getAnnotationsUrl(this.language ?? 'native');
    // Initialise localisation store lazily to ensure the readOnlyMode value is set
    await this.initialiseDocument();
  },
  beforeUnmount() {
    document.removeEventListener('keydown', this.keyDownHandler);
    this.$log.warn('beforeUnmount. Unloading pdf');
    this.unload();
  },
  unmounted() {
    this.$log.info('Unmounting pdf reader');
    if (isSet(this.channelMetricSelected)) {
      this.channelMetricSelected.close();
    }
    this.resetAnnotations();
  },
  methods: {
    ...mapActions({
      annotationsLazyInit: 'annotations/lazyInit',
      resetAnnotations: 'annotations/reset',
    }),
    reset() {
      this.$log.debug('Reset called');
      this.cachedAnnotationNodes = {};
      this.instance = null;
      this.selectedAnnotations = [];
    },
    getAnnotationsUrl(language = 'native') {
      switch (language) {
        case 'en':
          return this.filePaths.annotations_en;
        default:
          return this.filePaths.annotations;
      }
    },
    getPDFUrl(language = 'native') {
      switch (language) {
        case 'en':
          return this.filePaths.pdf_en;
        default:
          return this.filePaths.pdf;
      }
    },
    async toggleSelectedLanguage() {
      this.language = this.language === 'en' ? 'native' : 'en';
      this.$log.debug('Changing language of annotations to', this.language);
      // Re-initialising document as annotations and pdf change when new language is selected.
      this.unload();
      this.reset();
      this.pdfUrl = this.getPDFUrl(this.language);
      this.annotationsUrl = this.getAnnotationsUrl(this.language);
      await this.initialiseDocument();
      this.redrawToolbarItems();
    },
    async initialiseAnnotations() {
      this.annotationsLazyInit({
        annotationsUrl: this.annotationsUrl,
        language: this.language,
      })
        .then(() => {
          this.$log.info('Annotations loaded successfully');
        })
        .catch((e) => {
          this.$log.error(e);
          this.toast.error('Error fetching annotations for this document\'s PDF');
          throw e;
        });
    },
    async initialiseDocument() {
      this.$log.debug(`Initialising ${this.language} document (pdf: ${this.pdfUrl}, annotations: ${this.annotationsUrl}`);
      try {
        await Promise.all([this.initialisePDF(), this.initialiseAnnotations()]);
        this.$log.info('Successfully loaded annotation and document');
        if (this.channelMetricSelected === null) {
          this.setupBroadcastChannelWithMetricTable();
        }
        document.addEventListener('keydown', this.keyDownHandler);
        await this.deleteAllAnnotationsOnPDF();
      } catch (error) {
        this.toast.error('Failed to initialise document');
      }
    },
    setupBroadcastChannelWithMetricTable() {
      /* Receive a metric and show it in the pdf */
      this.channelMetricSelected = new BroadcastChannel('metric-selected');
      this.channelMetricSelected.onmessage = this.onMetricTableCellSelected;
    },
    onMetricTableCellSelected(message) {
      const messageData = message.data;
      this.$log.info('onMetricTableCellSelected: ', this.documentRequestId, ' received: ', messageData);
      if (String(messageData.documentRequestId) === String(this.documentRequestId)) {
        this.selectedInfoChanged(messageData);
      }
    },
    validateAnnotationInfo(annotationInfo) {
      if (!isSet(annotationInfo.nid) || !isSet(annotationInfo.page)) {
        throw Error(`annotationInfo nid/page not set: ${JSON.stringify(annotationInfo)}`);
      }
      if (!Number.isFinite(annotationInfo.page)) {
        throw Error(`annotationInfo page not typed correctly: ${JSON.stringify(annotationInfo)}`);
      }
      if (!isString(annotationInfo.nid)) {
        throw Error(`annotationInfo nid not typed correctly: ${JSON.stringify(annotationInfo)}`);
      }
    },
    /**
     * Process selection of new cell/annotations
     *
     * @param {{documentRequestId: string, annotations: {AnnotationInfo}[]}} newInfo: newInfo
     */
    async selectedInfoChanged(newInfo) {
      if (isSet(this.instance) && isSet(newInfo?.annotations)) {
        this.$log.info('selectedInfoChanged!');
        newInfo.annotations.forEach((a) => this.validateAnnotationInfo(a));
        await this.processExternalAnnotationsReceived(newInfo.annotations);

        if (this.selectedAnnotations.length) {
          const earliestAnnotation = this.getSelectedAnnotationWithEarliestPage();

          this.$log.info('firstNewlySelectedPage:', earliestAnnotation.primary.customData.page);
          if (this.shouldJumpToAnnotation) {
            await this.jumpToSelectedAnnotationSet(earliestAnnotation);
          }
        }
      }
    },
    /**
     * @param {SelectedAnnotationSet} selected
     * @return Promise<void>
     */
    async jumpToSelectedAnnotationSet(selected) {
      const { page, nid } = selected.primary.customData;
      const backendAnnotation = this.annotationByPageAndId(page, nid);
      this.$log.info('jumpToSelectedAnnotationSet:', page, nid, backendAnnotation);
      const zoomRect = this.getRectToZoomToForMetric(page - 1, [backendAnnotation]);

      return this.instance.jumpToRect(page - 1, zoomRect);
    },
    getSelectedAnnotationWithEarliestPage() {
      const minPageIdx = this.selectedAnnotations
        .map((sa) => sa.primary.customData.page)
        .reduce((prev, cur, idx, arr) => (cur < arr[prev] ? idx : prev), 0);

      return this.selectedAnnotations[minPageIdx];
    },
    drawAllAnnotations() {
      this.$log.info('Drawing all annotations...');
      const buildAnnotations = [];
      if (this.annotations.length === 0) {
        this.$log.warn('No annotations provided for document');
        return Promise.resolve();
      }

      let numNodes = 0;
      for (const node of Object.values(this.annotations)) { // eslint-disable-line no-restricted-syntax
        numNodes += Object.keys(node).length;
      }
      if (numNodes > this.thresholdForNotRenderingAllAnnotations) {
        // this.toast.warning('Document has over 10,000 interactive data points. Not rendering datapoints by default');
        return Promise.resolve();
      }

      const tBuild = performance.now();
      for (const [page, inPage] of Object.entries(this.annotations)) { // eslint-disable-line no-restricted-syntax
        for (const [nodeId, a] of Object.entries(inPage)) { // eslint-disable-line no-restricted-syntax
          const overrides = {};
          const customData = {
            nid: nodeId,
            page,
            isWord: this.isWordNode(a),
          };
          buildAnnotations.push(this.buildAnnotation(page - 1, a, false, customData, overrides, this.annotations.length === 1));
        }
      }
      this.$log.info('Building annotations took:', performance.now() - tBuild);

      this.$log.info('Built annotations', 'first one:', buildAnnotations[0], 'total count:', buildAnnotations.length);
      const t0 = performance.now();
      return this.instance.create(buildAnnotations).then(() => {
        const t1 = performance.now();
        this.$log.info(`Call to create annotations took ${t1 - t0} milliseconds.`);
      });
    },
    async fetchFilePaths(docId) {
      if (this.offline || process.env.NODE_ENV === 'development' || docId === '1000001') {
        this.$log.debug('Using default file paths.');
        return {
          pdf: this.localDocumentPath || `${window.location.origin}/local_documents/performance.pdf`,
          pdf_en: `${window.location.origin}/local_documents/performance_en.pdf`,
          annotations: `${window.location.origin}/local_documents/performance_annotations.json`,
          annotations_en: `${window.location.origin}/local_documents/performance_annotations_en.json`,
        };
      }

      const filePaths = await this.getDocumentLinks(docId);
      this.$log.info('S3 documents: ', filePaths);
      return filePaths;
    },
    async getDocumentLinks(docId) {
      if (docId === null || docId === undefined) {
        throw Error('PDF does not exist');
      }
      const path = `documentrequest/${docId}/document`;
      this.$log.info('Getting pdf: ', path);
      const idToken = this.$store.getters['authenticate/idToken'];

      return (new Api(process.env, idToken)).get(path)
        .catch((e) => {
          this.toast.error('Something went wrong while fetching the document links');
          this.$log.error('Error while getting document links: ', e);
          throw e;
        });
    },
    computeAnnotationBoundingBox(pageIndex, backendAnnotation, hasBorder, isSingleAnnotation) {
      const {
        x, y, w, h,
      } = this.getBackendAnnotationLoc(backendAnnotation);
      const pageInfo = this.instance.pageInfoForIndex(
        pageIndex,
      );
      if (pageInfo === null || pageInfo.width === undefined || pageInfo.width === null) {
        this.$log.error('Page info:', pageInfo, 'annotation:', backendAnnotation, 'pageIndex:', pageIndex);
      }
      const { width, height } = this.instance.pageInfoForIndex(
        pageIndex,
      );
      const borderW = hasBorder ? this.annotationBorderWidth : 0;
      const smallAreaFactor = isSingleAnnotation && w * h < 0.0004 ? 5 : 0;
      const annotationLeft = (x * width) - borderW - smallAreaFactor;
      const annotationTop = (y * height) - borderW - smallAreaFactor;
      const annotationWidth = (w * width) + (borderW + smallAreaFactor) * 2;
      const annotationHeight = (h * height) + (borderW + smallAreaFactor) * 2;
      return {
        annotationLeft, annotationTop, annotationWidth, annotationHeight, borderW,
      };
    },
    buildAnnotation(pageIndex, backendAnnotation, hasBorder, customData, annotationOverrides = {}, isSingleAnnotation = false) {
      const {
        annotationLeft, annotationTop, annotationWidth, annotationHeight, borderW,
      } = this.computeAnnotationBoundingBox(pageIndex, backendAnnotation, hasBorder, isSingleAnnotation);

      const annotationParams = {
        pageIndex,
        boundingBox: new PSPDFKit.Geometry.Rect({
          left: annotationLeft,
          top: annotationTop,
          width: annotationWidth,
          height: annotationHeight,
        }),
        strokeWidth: borderW,
        customData,
        ...annotationOverrides,
      };

      return new PSPDFKit.Annotations.RectangleAnnotation(annotationParams);
    },
    getBackendAnnotationLoc(backendAnnotation) {
      return {
        x: backendAnnotation.l[0],
        y: backendAnnotation.l[1],
        w: backendAnnotation.l[2],
        h: backendAnnotation.l[3],
      };
    },
    /**
     * Returns:
     *  An empty list if the annotation we're searching for is not found
     *  A first element if the primary annotation is found
     *  A second element if a row is found
     *  A third element if a column is found
     */
    getBackendCrossHairAnnotations(pageIndex, nodeId) {
      // Primary annotation:
      const backendAnnotation = this.annotationByPageAndId(pageIndex + 1, nodeId);
      const backendAnnotations = [];
      if (!isSet(backendAnnotation)) {
        return [];
      }
      backendAnnotations.push(backendAnnotation);

      // Row annotation:
      if (!isSet(backendAnnotation.tr)) {
        return backendAnnotations;
      }
      const rowBackendAnnotation = this.annotationByPageAndId(pageIndex + 1, backendAnnotation.tr);
      if (!isSet(rowBackendAnnotation)) {
        if (nodeId !== '-1') { // nodeId -1 means datapoint doesn't have corresponding annotation in pdf
          this.$log.warn(`Unable to locate extracted annotation for table row (ID: ${nodeId})`);
        }
        return backendAnnotations;
      }
      backendAnnotations.push(rowBackendAnnotation);

      // Column annotation:
      if (!isSet(backendAnnotation.tc)) {
        return backendAnnotations;
      }
      const colBackendAnnotation = this.annotationByPageAndId(pageIndex + 1, backendAnnotation.tc);
      if (!isSet(colBackendAnnotation)) {
        if (nodeId !== '-1') { // nodeId -1 means datapoint doesn't have corresponding annotation in pdf
          this.$log.warn(`Unable to locate extracted annotation for table row (ID: ${nodeId})`);
        }
        return backendAnnotations;
      }
      backendAnnotations.push(colBackendAnnotation);

      return backendAnnotations;
    },
    /**
     * For table annotations, return a crosshair rather than 3 distinct node-based annotations
     */
    transformAnnotations(backendAnnotations, pageIndex) {
      const transformedAnnotations = backendAnnotations;
      if (backendAnnotations.length < 2) {
        return transformedAnnotations;
      }
      const { width, height } = this.instance.pageInfoForIndex(
        pageIndex,
      );
      const borderWidth = this.annotationBorderWidth / width;
      const borderHeight = this.annotationBorderWidth / height;

      transformedAnnotations[1] = this.transformRowAnnotation(backendAnnotations, borderWidth, borderHeight);
      if (backendAnnotations.length < 3) {
        return transformedAnnotations;
      }

      transformedAnnotations[2] = this.transformColumnAnnotation(backendAnnotations, borderWidth, borderHeight);

      return transformedAnnotations;
    },
    transformRowAnnotation(backendAnnotations, borderWidth, borderHeight) {
      const crosshairRow = clonedeep(backendAnnotations[1]);
      // Set width of row annotation to reach the primary annotation
      crosshairRow.l[2] = backendAnnotations[0].l[0] - crosshairRow.l[0] - borderWidth;
      // Set height to an additional amount to cover the selected cell's border
      this.$log.info('Adding height:', borderHeight);
      crosshairRow.l[3] += borderHeight * 2;
      crosshairRow.l[1] -= borderHeight;

      return crosshairRow;
    },
    transformColumnAnnotation(backendAnnotations, borderWidth, borderHeight) {
      const crosshairCol = clonedeep(backendAnnotations[2]);
      const primaryX = backendAnnotations[0].l[0];
      const primaryW = backendAnnotations[0].l[2];
      const primaryX2 = primaryX + primaryW;
      const colX = crosshairCol.l[0];
      const colW = crosshairCol.l[2];
      const colX2 = colX + colW;

      // Set x of col annotation to min of itself or the primary node
      const crosshairX = Math.min(primaryX, colX);
      crosshairCol.l[0] = crosshairX;

      // Set width of col annotation so that it reaches max of primary/its own x2
      if (primaryX2 > colX2) {
        // Using primary's X2:
        crosshairCol.l[2] = primaryX2 - crosshairX;
      } else {
        // Using column annotation's X2
        crosshairCol.l[2] = colX2 - colX;
      }

      // Set height of col annotation to reach the primary annotation
      crosshairCol.l[3] = backendAnnotations[0].l[1] - crosshairCol.l[1] - borderHeight;
      crosshairCol.l[2] += borderWidth * 2;
      crosshairCol.l[0] -= borderWidth;

      return crosshairCol;
    },
    /**
     * @param {{AnnotationInfo}[]} annotations: annotation information
     */
    async drawAndJumpToAnnotations(annotations) {
      const firstAnnotation = annotations[0];

      let backendAnnotationsForZoom;
      if (annotations.length === 1) {
        this.$log.info('Drawing and setting new selected annotation for single annotation (includes cross-hairs)');
        backendAnnotationsForZoom = await this.drawAndSetNewSelectedAnnotation(firstAnnotation);
      } else {
        backendAnnotationsForZoom = await this.drawAndSetNewSelectedAnnotations(annotations);
      }

      this.$log.info('backendAnnotations for Zoom:', backendAnnotationsForZoom);
      const zoomRect = this.getRectToZoomToForMetric(firstAnnotation.page - 1, backendAnnotationsForZoom);

      return this.instance.jumpToRect(firstAnnotation.page - 1, zoomRect);
    },
    /**
     * @param {AnnotationInfo}: annotation information
     * @return {BackendAnnotation[]}
     */
    async drawAndSetNewSelectedAnnotation({ nid, page }) {
      const pageIndex = page - 1;
      const nodeId = nid;
      const backendAnnotations = this.getBackendCrossHairAnnotations(pageIndex, nodeId);
      if (nodeId === '-1') { // nodeId -1 means datapoint doesn't have corresponding annotation in pdf
        return Promise.resolve();
      }
      if (!backendAnnotations.length) {
        this.toast.warning(`Unable to locate extracted annotation (ID: ${nodeId})`);
        return Promise.resolve();
      }
      const transformedAnnotations = this.transformAnnotations(backendAnnotations, pageIndex);

      const builtAnnotations = transformedAnnotations.map((ba, idx) => {
        const customData = {
          nid: nodeId,
          page: pageIndex + 1,
          isWord: this.isWordNode(ba),
          isCrosshair: true,
          isSelected: idx === 0,
        };
        return this.buildAnnotation(pageIndex, ba, idx === 0, customData, {}, transformedAnnotations.length === 1);
      });

      const t0 = performance.now();
      return this.instance.create(builtAnnotations).then((annotations) => {
        const t1 = performance.now();
        this.$log.info(`Call to create cross-hairs took ${t1 - t0} milliseconds.`);

        const annotation = annotations[0];
        const rowAnnotation = annotations.length > 1 ? annotations[1] : null;
        const colAnnotation = annotations.length > 2 ? annotations[2] : null;

        this.setSelectedAnnotations(annotation, rowAnnotation, colAnnotation);

        return transformedAnnotations;
      });
    },
    /**
     * @param {{AnnotationInfo}[]} annotations: annotation information
     * @return {BackendAnnotation[]}
     */
    async drawAndSetNewSelectedAnnotations(annotations) {
      await this.createAndDeleteNewlySelected(annotations);
      const firstSelected = this.selectedAnnotations[0];
      const { page, nid } = firstSelected.primary.customData;
      this.$log.info('First selected annotation after `drawAndSetNewSelectedAnnotations`. p/nid:', page, nid);

      return [this.annotationByPageAndId(page, nid)];
    },
    /**
     * The zoom rectangle may be larger than just the primary annotation if it
     * has a large horizontal/vertical crosshair.
     * 1st backendAnnotation is the primary (required)
     * 2nd is the horizontal (optional)
     * 3rd is the vertical (optional)
     */
    getRectToZoomToForMetric(pageIndex, backendAnnotations) {
      const {
        x, y, w, h,
      } = this.getBackendAnnotationLoc(backendAnnotations[0]);
      const { width, height } = this.instance.pageInfoForIndex(
        pageIndex,
      );

      let rectLeft = 0;
      if (backendAnnotations.length >= 2) {
        [rectLeft] = backendAnnotations[1].l;
      }
      this.$log.info('rectLeft:', rectLeft);

      let rectWidth;
      if (backendAnnotations.length >= 3) {
        rectWidth = backendAnnotations[1].l[2] + backendAnnotations[0].l[2];
      } else {
        rectWidth = Math.min(1, x + w + 0.05); // Add a 1-bounded padding of 5%
      }
      rectWidth = Math.max(0.3, rectWidth);
      this.$log.info('rectWidth:', rectWidth);

      const rectCoords = {
        left: rectLeft * width,
        top: y * height,
        width: rectWidth * width,
        height: h * height,
      };
      this.$log.info('zooming to:', rectCoords);

      return new PSPDFKit.Geometry.Rect(rectCoords);
    },
    setSelectedAnnotations(backendAnnotation, rowAnnotation, colAnnotation) {
      this.selectedAnnotations = [{
        primary: backendAnnotation,
        tableRowHeader: rowAnnotation,
        tableColumnHeader: colAnnotation,
      }];
    },

    async deleteAllAnnotationsOnPDF() {
      const pagesAnnotations = await Promise.all(
        Array.from({ length: this.instance.totalPageCount }).map((_, pageIndex) => this.instance.getAnnotations(pageIndex)),
      );
      const annotationIds = pagesAnnotations.flatMap((pageAnnotations) => pageAnnotations.map((annotation) => annotation.id).toArray());
      await this.instance.delete(annotationIds);
    },
    /**
     * @param currentlySelectedAnnotations
     * @param {String[]} ids: Node ids to delete
     *
     * @return Array: An updated array of the currently selected annotations
     */
    async deleteSelectedPrimaryAnnotationsWithIds(currentlySelectedAnnotations, ids) {
      const currentlySelected = clonedeep(currentlySelectedAnnotations);
      const annotationsToDelete = [];

      // Traverse array backwards so that we can remove items
      for (let i = currentlySelected.length - 1; i >= 0; i--) {
        const selectedAnnotationSet = currentlySelected[i];
        const { primary } = selectedAnnotationSet;
        if (isSet(primary) && ids.includes(primary.customData.nid)) {
          Object.values(selectedAnnotationSet).forEach((annotation) => {
            if (annotation !== null) {
              annotationsToDelete.push(annotation.id);
            }
          });
          currentlySelected.splice(i, 1);
        }
      }
      this.$log.info('Deleting annotations:', annotationsToDelete);

      if (!annotationsToDelete.length) {
        return [];
      }

      await this.instance.delete(annotationsToDelete)
        .catch((e) => {
          this.$log.error('Error while deleting selected annotations', e);
        });

      return currentlySelected;
    },
    async initialisePDF() {
      this.$log.info('Loading PDF:', this.pdfUrl);
      this.unload();

      // PSPDFKit freezes the Options object to prevent changes after the first load.
      if (!Object.isFrozen(PSPDFKit.Options)) {
        // Normally PDFs without permission to be modified would prevent the line
        // tool to show up. We ignore permissions to make sure the line tool shows
        // up even for these documents.
        PSPDFKit.Options.IGNORE_DOCUMENT_PERMISSIONS = true;
      }

      return PSPDFKit.load({
        document: this.pdfUrl,
        container: this.containerSelector,
        licenseKey: this.licenseKey,
        baseUrl: this.baseUrl,
        toolbarItems: this.toolbarItems(),
        styleSheets: [this.enableUXRefresh ? `${this.baseUrl}custom-pspdfkitv2.css` : `${this.baseUrl}custom-pspdfkit.css`],
        customRenderers: {
          Annotation: this.renderCustomAnnotation,
        },
        isEditableAnnotation: (annotation) => !(annotation instanceof PSPDFKit.Annotations.TextAnnotation),
      })
        .then((instance) => {
          this.instance = instance;
          if (!this.readOnlyMode) {
            instance.addEventListener('page.press', this.onPagePress);
            instance.addEventListener('annotations.create', this.onAnnotationCreated);
            instance.contentDocument.addEventListener('keydown', this.keyDownHandler, true);
            instance.setViewState((viewState) => (
              viewState.set('keepSelectedTool', true)
            ));
          }
        })
        .catch((err) => {
          this.$log.error(err);
          this.toast.error('Something went wrong while trying to render this document');
          PSPDFKit.unload(this.containerSelector);
        });
    },
    keyDownHandler(event) {
      // Keyboard shortcut (Alt + s) for multiline selection
      if ((event.altKey || event.metaKey) && event.keyCode === 83) {
        this.startMultiLineSelection();
      }
    },
    startMultiLineSelection() {
      this.$log.info('Starting multi-line selection');
      this.instance.setViewState((v) => v.set('interactionMode', PSPDFKit.InteractionMode.SHAPE_LINE));
    },
    onAnnotationCreated(createdAnnotations) {
      const annotation = createdAnnotations.first();
      if (annotation instanceof PSPDFKit.Annotations.LineAnnotation) {
        this.onLineAnnotationCreated(annotation);
      }
      /**
       * When a user activates the rectangle tool, the interaction mode becomes 'SHAPE_RECTANGLE'
       * When they draw and drag the rectangle over their desired text, a rectangle annotation is created.
       * The creation of this annotation must be distinguished from the other rectangle annotations
       * (the ones which highlight the currently selected nodes).
       * The following condition is the closest solution found to achieve and handle annotation
       * selection within the bounding box of the created rectangle annotation.
       */
      if (annotation instanceof PSPDFKit.Annotations.RectangleAnnotation
        && this.instance.viewState.interactionMode === 'SHAPE_RECTANGLE'
        && annotation.customData === null
      ) {
        this.onSelectAnnotationsWithinBoundingBox(annotation);
      }
    },
    async onLineAnnotationCreated(lineAnnotation) {
      this.$log.info('onLineAnnotationCreated', lineAnnotation, lineAnnotation.boundingBox);
      // Get all annotations that intersect with the line
      const pageNum = lineAnnotation.pageIndex + 1;
      const treeForPage = this.annotationTrees[pageNum];
      if (!isSet(treeForPage)) {
        this.$log.error('No tree for page');
        return Promise.reject();
      }
      const { width, height } = this.instance.pageInfoForIndex(lineAnnotation.pageIndex);

      const startCoords = this.normaliseCoords(lineAnnotation.startPoint.x, lineAnnotation.startPoint.y, width, height);
      const endCoords = this.normaliseCoords(lineAnnotation.endPoint.x, lineAnnotation.endPoint.y, width, height);
      this.instance.delete(lineAnnotation); // Note, not waiting for this async operation

      const rTreeAnnotations = rTreeAnnotationsThatIntersectLine(
        treeForPage,
        startCoords.x,
        startCoords.y,
        endCoords.x,
        endCoords.y,
      );
      this.$log.info('Found rTree annotations:', rTreeAnnotations);

      // 'Select' these annotations
      const aLocators = rTreeAnnotations.map((a) => createSelectedInfo(pageNum, a.id));

      // Reset currently selected annotations
      await this.processResetAnnotations().then(async () => {
        await this.deleteAllAnnotationsOnPDF();
      });

      return this.processAdditionalAnnotationsSelected(aLocators);
    },
    async onSelectAnnotationsWithinBoundingBox(rectangleAnnotation) {
      this.$log.info('onSelectAnnotationsWithinBoundingBox', rectangleAnnotation, rectangleAnnotation.boundingBox);

      const pageNum = rectangleAnnotation.pageIndex + 1;
      const treeForPage = this.annotationTrees[pageNum];
      if (!isSet(treeForPage)) {
        this.$log.error('No tree for page');
        return Promise.reject();
      }

      const pageInfo = this.instance.pageInfoForIndex(rectangleAnnotation.pageIndex);
      const { boundingBox } = rectangleAnnotation;
      const {
        left, top, width, height,
      } = boundingBox;

      // Compute the coordinates of the rectangle's vertices
      const coords = {
        topLeft: [left, top],
        topRight: [left + width, top],
        bottomLeft: [left, top + height],
        bottomRight: [left + width, top + height],
      };

      // Normalise the coordinates with respect to the width and height of the page
      const normalisedCoords = {};
      Object.keys(coords).forEach((key) => {
        const [x, y] = coords[key];
        normalisedCoords[key] = this.normaliseCoords(x, y, pageInfo.width, pageInfo.height);
      });

      // Find all annotations which reside within the bounding box (i.e., the rectangle)
      const rTreeAnnotations = rTreeAnnotationsInsideBoundingBox(
        treeForPage,
        normalisedCoords.topLeft.x,
        normalisedCoords.topLeft.y,
        normalisedCoords.topRight.x,
        normalisedCoords.bottomRight.y,
      );

      const parentOnlyAnnotations = rTreeAnnotations.filter((a) => {
        const annotation = this.annotationByPageAndId(rectangleAnnotation.pageIndex + 1, a.id);
        return !this.isWordNode(annotation);
      });

      const aLocators = parentOnlyAnnotations.map((a) => ({
        page: pageNum,
        nid: a.id,
      }));

      // Reset currently selected annotations
      await this.processResetAnnotations().then(async () => {
        await this.deleteAllAnnotationsOnPDF();
      });

      // Proccess annotations for selection
      return this.processAdditionalAnnotationsSelected(aLocators, { filterOutNonWordNodes: false, filterOutWordNodes: true });
    },
    async createAndDeleteNewlySelected(infos) {
      this.$log.info('selected Annotation ids before Cr/Del:', this.selectedAnnotations.map((sa) => sa.primary.customData.nid));

      const alsToProcess = this.collectAnnotationsToCreateAndDelete(infos, this.selectedAnnotations);

      this.$log.info('alsToProcess Build + Delete:', alsToProcess);
      const newlySelected = await this.buildSelectedAnnotations(alsToProcess.toCreate);
      this.selectedAnnotations = this.selectedAnnotations.concat(newlySelected);

      const selectedAfterDeletion = await this.deleteSelectedPrimaryAnnotationsWithIds(
        clonedeep(this.selectedAnnotations), alsToProcess.toDelete.map((al) => al.nid),
      );
      this.selectedAnnotations = selectedAfterDeletion;

      const selectedAnnotationIdsAfter = this.selectedAnnotations.map((sa) => sa.primary.customData.nid);
      this.$log.info('selected Annotation ids after Cr/Del:', selectedAnnotationIdsAfter);

      return selectedAnnotationIdsAfter;
    },
    async buildSelectedAnnotations(infos) {
      /* Add selected annotations without destroying an previous annotations and without changing the viewport */
      const builtAnnotations = infos.map((info) => {
        const customData = {
          nid: info.nid,
          page: info.page,
          isWord: info.isWord,
          isCrosshair: false,
          isSelected: true,
        };
        const backendAnnotation = this.annotationByPageAndId(info.page, info.nid);
        return this.buildAnnotation(info.page - 1, backendAnnotation, true, customData, {}, infos.length === 1);
      });

      return this.instance.create(builtAnnotations)
        .then((annotations) => annotations.map((a) => ({ primary: a })));
    },
    /**
     * @param {AnnotationInfo} info: Info needed to build annotation + crosshairs
     *
     * @return {Promise<[{SelectedAnnotationSet}]>}
     */
    async buildSelectedAnnotationWithCrosshairs({ nid, page }) {
      /* Currently we only draw a crosshair for a single annotation at a time */
      const pageIndex = page - 1;
      const backendAnnotations = this.getBackendCrossHairAnnotations(pageIndex, nid);
      this.$log.info('buildSelectedAnnotationWithCrosshairs, backendAnnotations:', backendAnnotations);
      if (nid === '-1') { // nodeId -1 means datapoint doesn't have corresponding annotation in pdf
        return [];
      }
      if (!backendAnnotations.length) {
        this.toast.warning(`Unable to locate extracted annotation (ID: ${nid})`);
        return [];
      }
      const transformedAnnotations = this.transformAnnotations(backendAnnotations, pageIndex);

      const builtAnnotations = transformedAnnotations.map((ba, idx) => {
        const customData = {
          nid,
          page,
          isWord: this.isWordNode(ba),
          isCrosshair: true,
          isSelected: idx === 0,
        };
        return this.buildAnnotation(pageIndex, ba, idx === 0, customData, {}, transformedAnnotations.length === 1);
      });
      const annotations = await this.instance.create(builtAnnotations);

      return [{
        primary: annotations[0],
        ...(annotations.length > 1 && { tableRowHeader: annotations[1] }),
        ...(annotations.length > 2 && { tableColumnHeader: annotations[2] }),
      }];
    },
    appendPrimaryAnnotation(pdfAnnotation) {
      this.$log.info('Appending annotation:', pdfAnnotation);
      this.selectedAnnotations.push({ primary: pdfAnnotation });
    },
    onPagePress(event) {
      this.$log.info(event);
      if (event.nativeEvent.shiftKey) {
        this.$log.info('Shift key is pressed');
      } else {
        this.$log.info('Shift key is NOT pressed');
      }

      this.$log.info('page pressed:', event.point, event.pageIndex);
      const normalisedInfo = this.pagePressEventToNormalisedValues(event);
      this.$log.info('normalisedInfo:', normalisedInfo);

      const t0 = performance.now();
      const treeNodes = this.lookupAnnotationFromCoordsWithTree(normalisedInfo);
      this.$log.info(`Call to find node RBUSH took ${performance.now() - t0} milliseconds.`);
      this.$log.info('Call to find node RBUSH result', treeNodes);
      if (treeNodes === null) {
        this.$log.error('No tree nodes for page number:', event.pageIndex + 1);
        return;
      }

      const pageNum = +event.pageIndex + 1;
      const annotations = this.getAnnotationFromTreeNodes(treeNodes, pageNum);

      if (annotations) {
        this.$log.debug('Found annotations from tree nodes: ', annotations);
        this.processReplacementAnnotationsSelected(annotations);
      }
    },
    calculateAnnotationArea(annotation) {
      return (annotation.maxX - annotation.minX) * (annotation.maxY - annotation.minY);
    },
    getAnnotationFromTreeNodes(treeNodes, pageNum) {
      // Given a list of (multiple) tree nodes, find the most relevant node
      let chosenAnnotations = [];
      let chosenAnnotationArea = null;
      for (const treeNode of treeNodes) { // eslint-disable-line no-restricted-syntax
        const nid = treeNode.id;
        const annotation = this.annotationByPageAndId(pageNum, nid, 'native');

        // Prioritising annotations with children (words).
        if (objHasKey(annotation, 'c')) {
          const annotationArea = this.calculateAnnotationArea(treeNode);

          // Prioritize smallest nodes.
          // If chosen node, take the node and all its related words as the chosen annotations.
          if (chosenAnnotationArea === null || chosenAnnotationArea > annotationArea) {
            chosenAnnotationArea = annotationArea;
            const annotations = [{ page: pageNum, nid, isWord: false }];
            annotation.c.forEach((child) => {
              annotations.push({ page: pageNum, nid: child.toString(), isWord: true });
            });
            chosenAnnotations = annotations;
          }
        }

        // Accept child annotation if necessary (but keep looking).
        if (!chosenAnnotations.length) {
          chosenAnnotations = [{ page: pageNum, nid, isWord: this.isWordNode(annotation) }];
        }

        this.$log.info('Call to find node treeNode:', treeNode, '; currently chosen annotation:', chosenAnnotations);
      }
      return chosenAnnotations;
    },
    lookupAnnotationFromCoordsWithTree({ pageIndex, x, y }) {
      this.$log.info('this.annotationTrees', this.annotationTrees);
      const tree = this.annotationTrees[pageIndex + 1];
      if (!isSet(tree)) {
        this.$log.warn('No r-tree for pageIndex:', pageIndex);
        return [];
      }

      return tree.search({
        minX: x, minY: y, maxX: x, maxY: y,
      });
    },
    pagePressEventToNormalisedValues(event) {
      const pressPoint = event.point;
      const { width, height } = this.instance.pageInfoForIndex(
        event.pageIndex,
      );
      const coords = this.normaliseCoords(pressPoint.x, pressPoint.y, width, height);

      return { x: coords.x, y: coords.y, pageIndex: event.pageIndex };
    },
    normaliseCoords(x, y, pageWidth, pageHeight) {
      return { x: x / pageWidth, y: y / pageHeight };
    },
    broadcastAnnotations(annotations, resetSelectedMetrics = false) {
      const channelNodeSelected = new BroadcastChannel('node-selected');
      annotations.forEach((a) => this.validateAnnotationInfo(a));

      const message = {
        annotations: JSON.parse(JSON.stringify(annotations)),
        documentRequestId: this.documentRequestId,
        resetSelectedMetrics,
      };
      channelNodeSelected.postMessage(message);
      channelNodeSelected.close();
    },
    renderCustomAnnotation({ annotation }) {
      if (!(annotation instanceof PSPDFKit.Annotations.RectangleAnnotation)) {
        return null; // Render the default UI for non-rectangle annotations
      }

      if (!objHasKey(this.cachedAnnotationNodes, annotation.id)) {
        const annotationNode = document.createElement('div');
        annotationNode.classList.add('freyda-annotation');
        if (annotation.customData?.isCrosshair) {
          annotationNode.classList.add('freyda-annotation-crosshair');
        } else {
          annotationNode.classList.add('freyda-annotation-clickable');
        }
        if (annotation.customData?.isSelected) {
          annotationNode.classList.add('freyda-annotation-selected');
        }
        const isCrosshair = (a) => isSet(a.customData) && a.customData.isCrosshair;
        this.instance.setIsEditableAnnotation(isCrosshair);
        this.cachedAnnotationNodes[annotation.id] = annotationNode;
      }

      return {
        node: this.cachedAnnotationNodes[annotation.id],
        append: false,
      };
    },
    unload() {
      this.$log.info('Unload called');
      if (isSet(this.instance)) {
        this.instance.contentDocument.removeEventListener('keydown', this.keyDownHandler);
        this.instance.removeEventListener('page.press', this.onPagePress);
        PSPDFKit.unload(this.instance);
      }

      PSPDFKit.unload(this.containerSelector);
    },
    getIconUrl(filename) {
      if (!filename) return '';
      // eslint-disable-next-line global-require, import/no-dynamic-require
      return require(`../../assets/${filename}`);
    },
    redrawToolbarItems() {
      this.instance.setToolbarItems(this.toolbarItems());
    },
    toolbarItems() {
      const removeAnnotationLinkItem = {
        type: 'custom',
        id: 'remove-annotation-link',
        title: 'Remove association between cell and document annotation',
        icon: this.enableUXRefresh ? this.getIconUrl('Link Broken.svg') : this.getIconUrl('link_broken.svg'),
        onPress: () => {
          this.$log.info('Removing annotation linked to current cell');
          this.processResetAnnotations();
        },
      };
      const toggleJumpTitle = this.shouldJumpToAnnotation
        ? 'Stop jumping to cell value\'s location in the document'
        : 'Jump to cell value\'s location in the document';
      const toggleJumpToAnnotationItem = {
        type: 'custom',
        id: 'toggle-jump-to-annotation',
        title: toggleJumpTitle,
        icon: this.enableUXRefresh ? this.getIconUrl('AnnotationJump.svg') : this.getIconUrl('jump.svg'),
        onPress: () => {
          this.$log.info('Toggling jumping to the selected cell\'s value in the document');
          this.shouldJumpToAnnotation = !this.shouldJumpToAnnotation;
          this.redrawToolbarItems();
        },
        selected: this.shouldJumpToAnnotation,
      };

      const toggleTranslationAnnotations = {
        type: 'custom',
        id: 'toggle-translation-annotations',
        title: this.language === 'en' ? 'Remove Translations' : 'Translate document to english',
        icon: this.getIconUrl('translate.svg'),
        onPress: () => {
          this.toggleSelectedLanguage();
        },
        selected: this.language === 'en',
      };

      const toggleCopyAnnotationTitle = this.shouldCopyAnnotation
        ? 'Select content in document to copy to clipboard'
        : 'Select to enable copying';
      const toggleCopyAnnotationToClipboard = {
        type: 'custom',
        id: 'toggle-copy-annotation',
        title: toggleCopyAnnotationTitle,
        icon: this.enableUXRefresh ? this.getIconUrl('Copy.svg') : this.getIconUrl('copy-regular.svg'),
        onPress: async () => {
          this.$log.info('Toggled copy annotation to clipboard');

          // Deselect current annotations.
          const currentlySelected = this.selectedAnnotations;
          const alsToProcess = this.collectAnnotationsToCreateAndDeleteForReplace([], currentlySelected);
          const updatedSelected = await this.renderUpdatedAnnotations(this.selectedAnnotations, alsToProcess);
          this.selectedAnnotations = updatedSelected;

          this.shouldCopyAnnotation = !this.shouldCopyAnnotation;
          this.redrawToolbarItems();
        },
        selected: this.shouldCopyAnnotation,
      };
      return [
        {
          type: 'sidebar-thumbnails',
        },
        {
          type: 'sidebar-document-outline',
        },
        {
          type: 'pager',
        },
        {
          type: 'pan',
          ...this.enableUXRefresh && { icon: this.getIconUrl('Hand.svg') },
        },
        {
          type: 'zoom-out',
          ...this.enableUXRefresh && { icon: this.getIconUrl('ZoomOut.svg') },

        },
        {
          type: 'zoom-in',
          ...this.enableUXRefresh && { icon: this.getIconUrl('ZoomIn.svg') },

        },
        {
          type: 'zoom-mode',
        },
        {
          type: 'spacer',
        },
        ...!this.readOnlyMode ? [removeAnnotationLinkItem] : [],
        toggleCopyAnnotationToClipboard,
        toggleJumpToAnnotationItem,
        ...!this.readOnlyMode ? [
          {
            type: 'line',
            disabled: this.language === 'en',
            ...this.enableUXRefresh && {
              icon: this.getIconUrl('LineTool.svg'),
              className: 'line-item-tool',
            },

          },
          {
            type: 'rectangle',
            ...this.enableUXRefresh && {
              icon: this.getIconUrl('RectangleTool.svg'),
              className: 'line-item-tool',
            },

          },
        ] : [],
        ...this.enableLanguageToggle ? [toggleTranslationAnnotations] : [],
        {
          type: 'print',
          ...this.enableUXRefresh && { icon: this.getIconUrl('Printer.svg') },
        },
        {
          type: 'search',
          ...this.enableUXRefresh && { icon: this.getIconUrl('Search.svg') },
        },
        {
          type: 'export-pdf',
          ...this.enableUXRefresh && { icon: this.getIconUrl('Export.svg') },
        },
        {
          type: 'debug',
        },
      ];
    },
    async processExternalAnnotationsReceived(annotationLocators) {
      this.$log.info('processExternalAnnotationsReceived:', annotationLocators);
      if (this.language === 'en') {
        // Translated annotation files do not contain word nodes. If annotations receieved contain word nodes, we must revert to the native document.
        const containsWordNode = annotationLocators.some((a) => this.isWordNode(this.annotationByPageAndId(a.page, a.nid, 'native')));
        if (containsWordNode) { await this.toggleSelectedLanguage(); }
      }
      const currentlySelected = this.selectedAnnotations;
      const alsToProcess = this.collectAnnotationsToCreateAndDeleteForReplace(annotationLocators, currentlySelected);
      const updatedSelected = await this.renderUpdatedAnnotations(this.selectedAnnotations, alsToProcess);

      this.selectedAnnotations = updatedSelected;
      this.$log.info('Updated selected annotations (received externally):', updatedSelected);
    },
    async processResetAnnotations() {
      this.$log.info('processResetAnnotations');
      const currentlySelected = this.selectedAnnotations;
      const alsToProcess = this.collectAnnotationsToCreateAndDeleteForReplace([], currentlySelected);
      const updatedSelected = await this.renderUpdatedAnnotations(this.selectedAnnotations, alsToProcess);

      this.selectedAnnotations = updatedSelected;
      this.$log.info('Updated selected annotations (received externally):', updatedSelected);
      this.broadcastSelectedAnnotations(updatedSelected);
    },
    filterOutChildAnnotations(annotationLocators) {
      return annotationLocators.filter((a) => {
        const annotation = this.annotationByPageAndId(a.page, a.nid);
        return !this.isWordNode(annotation);
      });
    },
    async processAdditionalAnnotationsSelected(annotationLocators, { filterOutNonWordNodes, filterOutWordNodes } = {
      filterOutNonWordNodes: true,
      filterOutWordNodes: false,
    }) {
      this.$log.info('processNewAnnotationsSelected:', annotationLocators);

      // Filter out child/word nodes if requested
      const filteredAnnotationLocators = filterOutWordNodes ? this.filterOutChildAnnotations(annotationLocators) : annotationLocators;

      const currentlySelected = this.selectedAnnotations;
      const alsToProcess = this.collectAnnotationsToCreateAndDeleteForAppend(filteredAnnotationLocators, currentlySelected);

      // Filter out parent/non-word nodes if requested
      const filteredAlsToProcess = filterOutNonWordNodes ? this.filterOutParentalAnnotations(alsToProcess, this.annotations) : alsToProcess;

      // Update selected annotations
      const updatedSelected = await this.renderUpdatedAnnotations(this.selectedAnnotations, filteredAlsToProcess);
      this.selectedAnnotations = updatedSelected;
      this.$log.info('Updated selected annotations:', updatedSelected);
      this.broadcastSelectedAnnotations(updatedSelected);
    },
    async processReplacementAnnotationsSelected(annotationLocators) {
      this.$log.info('processReplacementAnnotationsSelected:', annotationLocators);
      const currentlySelected = this.selectedAnnotations;
      const alsToProcess = this.collectAnnotationsToCreateAndDeleteForReplace(annotationLocators, currentlySelected);
      const updatedSelected = await this.renderUpdatedAnnotations(this.selectedAnnotations, alsToProcess);

      this.selectedAnnotations = updatedSelected;
      this.$log.info('Updated selected annotations:', updatedSelected);

      // Copy annotation if the option is specified.
      let resetSelectedMetrics = false;
      if (this.shouldCopyAnnotation) {
        this.copyAnnotationToClipboard();
        this.shouldCopyAnnotation = false;
        this.redrawToolbarItems();
        resetSelectedMetrics = true;
      }

      this.broadcastSelectedAnnotations(updatedSelected, resetSelectedMetrics);
    },
    /**
     * @return {SelectedAnnotationSet}[]: The selected annotations after creation and deletion
     */
    async renderUpdatedAnnotations(currentlySelected, alsToProcess) {
      this.$log.info('renderUpdatedAnnotations, current:', currentlySelected, 'toProcess:', alsToProcess);
      const selectedAfterDeletion = await this.deleteSelectedPrimaryAnnotationsWithIds(
        clonedeep(currentlySelected), alsToProcess.toDelete.map((al) => al.nid),
      );
      this.$log.info('Renderer annotations after deletion:', selectedAfterDeletion);

      // Filter out any word nodes.
      const toCreateNodes = [];
      alsToProcess.toCreate.forEach((node) => {
        if (!objHasKey(node, 'isWord') || !node.isWord) {
          toCreateNodes.push(node);
        }
      });

      const buildAsCrosshairs = toCreateNodes.length === 1 && alsToProcess.toPersist.length === 0;
      this.$log.info('Rendering annotations (building as crosshairs:', buildAsCrosshairs, ') toCreateNodes:', toCreateNodes);
      const newlySelected = buildAsCrosshairs
        ? await this.buildSelectedAnnotationWithCrosshairs(alsToProcess.toCreate[0])
        : await this.buildSelectedAnnotations(alsToProcess.toCreate);

      const persistNids = new Set(alsToProcess.toPersist.map((al) => al.nid));
      const persistedSelected = clonedeep(currentlySelected)
        .filter((s) => persistNids.has(this.selectedAnnotationSetToAnnotationLocator(s).nid));
      this.$log.info('RenderUpdatedAnnotations post selected (P, C):', persistedSelected, newlySelected);

      return persistedSelected.concat(newlySelected);
    },
    broadcastSelectedAnnotations(newlySelected, resetSelectedMetrics = false) {
      const broadcastAnnotations = newlySelected.map((a) => {
        this.$log.info('building broadcast annotations:', a);
        return {
          nid: a.primary.customData.nid,
          page: a.primary.customData.page,
          isWord: a.primary.customData.isWord,
          data: this.annotationByPageAndId(a.primary.customData.page, a.primary.customData.nid),
        };
      });
      this.$log.info('broadcast annotations:', broadcastAnnotations);
      this.broadcastAnnotations(broadcastAnnotations, resetSelectedMetrics);
    },
    copyAnnotationToClipboard() {
      const copyText = this.selectedAnnotations[0];
      if (copyText) {
        const { page, nid } = copyText.primary.customData;
        const annotationText = this.annotationByPageAndId(page, nid).t;
        this.$log.info('Copying text to clipboard:', annotationText);
        navigator.clipboard.writeText(annotationText);
        this.toast.info(infoMessages.ANNOTATION_COPIED);
      } else {
        this.$log.info('No annotation text present');
        this.toast.warning(warningMessages.CANNOT_COPY_ANNOTATION);
      }
    },
    isWordNode(annotation) {
      return 'pid' in annotation;
    },
  },
};
</script>
